import "./index.scss";
const AccountTypesComparison = () => {
  return (
    <div className="comparison-container">
      <div className="comparison-inner-container">
        <h1 className="comparison-inner-container-title">STANDARD</h1>
        <h2>10,000$</h2>

        <ul>
          <li>1:50 Leverage</li>
          <li>Up to 15% margin loan</li>
          <li>Multiple tradable assets</li>
          <li>Work with a portfolio manager</li>
          <li>Daily market review</li>
          <li>Trading Academy</li>
        </ul>
      </div>
      <div className="comparison-inner-container">
        <h1 className="comparison-inner-container-title">SILVER</h1>
        <h2>50,000$</h2>

        <ul>
          <li>1:100 Leverage</li>
          <li>Up to 25% margin loan</li>
          <li>Full Access to our MetaTrader 5 platform</li>
          <li>Daily market review</li>
          <li>Daily portfolio progress report</li>
          <li>Work with a portfolio manager</li>
          <li>24/7 account monitoring by top analyst group</li>
        </ul>
      </div>

      <div className="comparison-inner-container">
        <h1 className="comparison-inner-container-title">GOLD</h1>
        <h2>100,000$</h2>

        <ul>
          <li>1:200 Leverage</li>
          <li>Up to 35% margin loan</li>
          <li>Access to our online MetaTrader 5 platform</li>
          <li>Daily market review</li>
          <li>Daily portfolio progress report</li>
          <li>Personal portfolio manager</li>
          <li>24/7 account monitoring by top analyst group</li>
          <li>Monthly session with certified accountant</li>
          <li>Lower spreads: up to 5% lower</li>
        </ul>
      </div>
      <div className="comparison-inner-container">
        <h1 className="comparison-inner-container-title">PLATINUM</h1>
        <h2>250,000$</h2>

        <ul>
          <li>1:300 leverage</li>
          <li>Up to 50% margin loan</li>
          <li>Monthly session with certified accountant</li>
          <li>24/7 account monitoring by top analyst group</li>
          <li>
            Trading Signals by SMS/WhatsApp delivered straight to your phone
          </li>
          <li>A 24/7 phone support from our executive trading team</li>
          <li>Personalized trading sessions and education</li>
          <li>Invitation to arbitrage trading events</li>
          <li>Lower spreads: 10% lower</li>
          <li>$250,000 of ADI insurance</li>
        </ul>
      </div>
      <div className="comparison-inner-container">
        <h1 className="comparison-inner-container-title">PLATINUM PRO</h1>
        <h2>500,000$</h2>

        <ul>
          <li>1:400 leverage</li>
          <li>Up to 75% margin loan</li>
          <li>Monthly session with certified accountant</li>
          <li>24/7 account monitoring by top analyst group</li>
          <li>
            Trading Signals by SMS/WhatsApp delivered straight to your phone
          </li>
          <li>A 24/7 phone support from our executive trading team</li>
          <li>Personalized trading sessions and education</li>
          <li>Invitation to arbitrage trading events</li>
          <li>Lower spreads: 20% lower</li>
          <li>$250,000 of ADI insurance</li>
          <li>Unlimited access to brokers</li>{" "}
          <li>Exclusive invitation to our VIP events</li>{" "}
          <li>End of year summary with certified Tax specialist</li>
        </ul>
      </div>
      <div className="comparison-inner-container">
        <h1 className="comparison-inner-container-title">DIAMOND VIP</h1>
        <h2>1,000,000$</h2>

        <ul>
          <li>1:500 Leverage</li>
          <li>Up to 100% margin loan</li>
          <li>Personal chief portfolio manager</li>
          <li>Personal certified accountant and tax specialist</li>
          <li>Access to our elite webinar library</li>
          <li>Lower spreads: 50% lower</li>
          <li>Enjoy 0% commission on local and global stocks</li>
          <li>Higher payouts</li>
          <li>Exclusive invitation to our VIP events</li>
          <li>$250,000 of ADI insurance</li>
        </ul>
      </div>
    </div>
  );
};

export default AccountTypesComparison;
