import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userServices } from "../../services/userServices";

export const getAllRegisteredUsers = createAsyncThunk(
  "getAllRegisteredUsers",
  async (payload) => {
    try {
      return await userServices.getAllRegisteredUsers(payload);
    } catch (e) {
      throw new Error(e);
    }
  }
);

export const getAllAgents = createAsyncThunk("getAllAgents", async () => {
  try {
    return await userServices.getAllAgents();
  } catch (e) {
    throw new Error(e);
  }
});

export const allRegisteredUsersSlice = createSlice({
  name: "users",
  initialState: {
    users: null,
    totalPages: 0,
    currentPage: 1,
    rowsPerPage: 10,
    totalCount: 0,
    agents: null,
    affiliates: null,
  },
  reducers: {
    getAllRegisteredUsers: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRegisteredUsers.fulfilled, (state, action) => {
        state.users = action.payload.users;
        state.affiliates = action.payload.affiliates;
        state.currentPage = action.payload.currentPage;
        state.rowsPerPage = action.payload.rowsPerPage;
        state.totalCount = action.payload.totalCount;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getAllAgents.fulfilled, (state, action) => {
        state.agents = action.payload;
      });
  },
});

export default allRegisteredUsersSlice.reducer;
