import * as React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Box, TableSortLabel, TextField } from "@mui/material";
import { getAllRegisteredUsers } from "../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import { displayCurrentMonth } from "../../../../helpers/utils";
import { ScoreOutlined } from "@mui/icons-material";
import { userServices } from "../../../../services/userServices";

function descendingComparator(a, b, orderBy) {
  if (orderBy === "amount") {
    if (+b[orderBy] < +a[orderBy]) {
      return -1;
    }
    if (+b[orderBy] > +a[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => {
    return [el, index];
  });
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return b[1] - a[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  // { id: "id", label: "Place", disablePadding: false },
  { id: "agent", label: "Agent", disablePadding: false },
  { id: "amount", label: "Amount", disablePadding: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: "#3E4396" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              width: "20%",
            }}
            key={headCell.id}
            align={headCell.id === "agent" ? "left" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

export default function RankingBoard() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const isUserAdmin = user?.role === "admin";

  const users = useSelector((state) => state.users.users);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const dispatch = useDispatch();
  const retentions = users?.filter((user) => user?.role === "retention");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const today = new Date();

  let month = today.getMonth() + 1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - retentions?.length) : 0;

  React.useEffect(() => {
    dispatch(getAllRegisteredUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retentionAchievements = retentions?.map((retention) =>
    Number(retention?.achievement)
  );
  const highestAchievementThisMonth = Math.max(
    ...(retentionAchievements || [0])
  );
  const handleAmountChange = (e, userId) => {
    const achievement = Number(e.target.value);
    if (!!achievement)
      userServices
        .updateUser(userId, {
          achievement,
        })
        .then(() => {
          dispatch(getAllRegisteredUsers());
        });
  };
  const highestDepositRetention = retentions?.sort(
    (a, b) => b.achievement - a.achievement
  );
  return (
    <>
      <div className="ranking-board-background"></div>
      <div className="ranking-board-root">
        <div className="ranking-board-card">
          <div>
            <h3 style={{ color: "#c0bebe" }}>
              Highest deposits for {displayCurrentMonth(month)}
            </h3>
            <h2 style={{ marginTop: "0.5rem", color: "white" }}>
              €{highestAchievementThisMonth}
            </h2>
            <p style={{ color: "gray" }}>
              {!!highestDepositRetention &&
                highestDepositRetention[0]?.firstName +
                  " " +
                  highestDepositRetention[0]?.lastName}
            </p>
          </div>
        </div>
        {/* <h1 className="ranking-board-header">
          Score Board {displayCurrentMonth(month)}
        </h1> */}
        <Paper
          style={{
            width: "700px",
            maxHeight: "500px",
            overflowY: "auto",
            overflowX: "hidden",
            margin: "0 auto",
            boxShadow: "0px 0px 20px 0px",
            color: "rgb(225, 221, 221)",
          }}
        >
          <TableContainer>
            <Table aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={retentions?.length}
              />
              <TableBody style={{ background: "#1F2A40" }}>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 retentions.slice().sort(getComparator(order, orderBy)) */}
                {retentions
                  ?.sort((a, b) => b.achievement - a.achievement)
                  .map((agent, index) => {
                    return (
                      <TableRow
                        hover={true}
                        role="checkbox"
                        tabIndex={-1}
                        key={agent?.id}
                      >
                        {/* <TableCell
                          style={{
                            fontSize: "1rem",
                            color: "#d1d1d1",
                            width: "50px",
                          }}
                          component="th"
                          align="left"
                          scope="agent"
                        >
                          {index + 1}
                        </TableCell> */}
                        <TableCell
                          style={{
                            fontSize: "1rem",
                            color: "#d1d1d1",
                            width: "150px",
                          }}
                          align="left"
                        >
                          {index + 1}.{`${agent?.firstName} ${agent?.lastName}`}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize:
                              index === 0
                                ? "1.4rem"
                                : index === 1
                                ? "1.3rem"
                                : index === 2
                                ? "1.2rem"
                                : "1rem",
                            fontWeight: "bold",
                            color:
                              index === 0
                                ? "#daa520"
                                : index === 1
                                ? "silver"
                                : index === 2
                                ? "#CD7F32"
                                : "gray",
                          }}
                          align="center"
                        >
                          €{agent.achievement}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              background: "#3E4396",
            }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={retentions?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}
