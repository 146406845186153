import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  AccountCircle,
  MoreVertOutlined,
  MoreHoriz,
  Close,
} from "@material-ui/icons";
import MenuIcon from "@mui/icons-material/Menu";
import * as React from "react";
import { useNavigate } from "react-router";
import "./index.scss";
import { changeOnlineActivity, logout } from "../../redux/users/userSlice";
import MyProSidebar from "../../pages/admin/components/sidebar";
import { userServices } from "../../services/userServices";

const pages = [
  "Platform",
  "Features",
  "Tools",
  "FAQ",
  "About us",
  // "Team",
  // "advisers",
  "We are hiring",
  "Locations",
  "Contact us",
];

const Header = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [navbarClass, setNavbarClass] = React.useState("navbar");
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  // const [settings, setSettings] = React.useState([
  //   "Trade",
  //   "Wallet",
  //   "Account",
  //   // "Team",
  //   // "Advisers",
  //   "Locations",
  //   "Contact Us",
  //   "Logout",
  // ]);

  const settings = [
    "Trade",
    "Wallet",
    "Personal Details",
    // "Team",
    // "Advisers",
    "Locations",
    "Contact Us",
    "Logout",
    user?.role === "admin"
      ? "Admin"
      : user?.role === "crm_manager"
      ? "CRM"
      : user?.role === "head_of_conversion"
      ? "Head of conversion"
      : user?.role === "retention"
      ? "Retention"
      : user?.role === "conversion" && "Conversion",
  ];
  const dispatch = useDispatch();

  const checkScrollDown = () => {
    if (window.scrollY > 100) {
      setNavbarClass("navbar-on-scroll");
    } else {
      setNavbarClass("navbar");
    }
  };

  // React.useEffect(() => {
  //   if (user?.role?.toLowerCase() === "retention") {
  //     setSettings([...settings, "Retention"]);
  //   } else if (user?.role?.toLowerCase() === "admin" ) {
  //     setSettings([...settings, "Admin"]);
  //   } else if (user?.role?.toLowerCase() === "conversion") {
  //     setSettings([...settings, "Conversion"]);
  //   }
  // }, [user?.role]);

  const uniqueSettings = [...new Set(settings.filter((el) => el))];

  React.useEffect(() => {
    window.addEventListener("scroll", checkScrollDown);
  }, [window.scroll()]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = async (e) => {
    if (e.target.outerText?.toLowerCase() === "platform") {
      navigate("/platform");
    } else if (e.target.outerText?.toLowerCase() === "features") {
      navigate("/features");
    } else if (e.target.outerText?.toLowerCase() === "tools") {
      navigate("/tools");
    } else if (e.target.outerText?.toLowerCase() === "faq") {
      navigate("/faq");
    } else if (e.target.outerText?.toLowerCase() === "about us") {
      navigate("/about-us");
    }
    // else if (e.target.outerText?.toLowerCase() === "team") {
    //   navigate("/our-team");
    // }
    // else if (e.target.outerText?.toLowerCase() === "advisers") {
    //   navigate("/our-advisers");
    // }
    else if (e.target.outerText?.toLowerCase() === "we are hiring") {
      navigate("/we-are-hiring");
    } else if (e.target.outerText?.toLowerCase() === "trade") {
      navigate("/trade");
    } else if (e.target.outerText?.toLowerCase() === "personal details") {
      navigate("/personal-details");
    } else if (e.target.outerText?.toLowerCase() === "wallet") {
      navigate("/wallet");
    } else if (e.target.outerText?.toLowerCase() === "locations") {
      navigate("/locations");
    } else if (e.target.outerText?.toLowerCase() === "admin") {
      navigate("/admin/users");
    } else if (e.target.outerText?.toLowerCase() === "crm") {
      navigate("/admin/users");
    } else if (e.target.outerText?.toLowerCase() === "head of conversion") {
      navigate("/admin/users");
    } else if (e.target.outerText?.toLowerCase() === "conversion") {
      navigate("/admin/users");
    } else if (e.target.outerText?.toLowerCase() === "retention") {
      navigate("/admin/users");
    } else if (e.target.outerText?.toLowerCase() === "affiliate") {
      navigate("/admin/affiliate");
    } else if (e.target.outerText?.toLowerCase() === "logout") {
      await userServices.changeOnlineActivity(user?.id, false);
      dispatch(logout());
      localStorage.clear();
      navigate("/");
    } else if (e.target.outerText?.toLowerCase() === "contact us") {
      navigate("/contact-us");
    } else {
      setAnchorElNav(null);
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const returnAppClassName = () => {
    if (
      window.location.href.includes("trade") ||
      window.location.href.includes("history") ||
      window.location.href.includes("wallet") ||
      window.location.href.endsWith("deposit") ||
      window.location.href.includes("withdraw") ||
      window.location.href.includes("transactions") ||
      window.location.href.includes("account-types") ||
      window.location.href.includes("personal-details") ||
      window.location.href.includes("security-trading") ||
      window.location.href.includes("account-documents")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (user?.role === "conversion" ||
    user?.role === "retention" ||
    user?.role === "admin" ||
    user?.role === "crm_manager" ||
    user?.role === "head_of_conversion") &&
    window.location.href.includes("/admin") ? (
    <MyProSidebar />
  ) : (
    <AppBar
      className={navbarClass}
      color="default"
      style={{
        background: returnAppClassName() ? "transparent" : "rgb(0 0 0 / 83%)",
        color: "white",
        height: returnAppClassName() ? "auto" : "90px",
        justifyContent: "center",
        top: returnAppClassName() && "1%",
      }}
      enableColorOnDark
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{ justifyContent: "space-between", height: "60px" }}
        >
          {user?.email || returnAppClassName() ? (
            <>
              <Typography
                className="header-image-container"
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2 }}
              >
                <img
                  height={210}
                  width={230}
                  alt="nordxglobal-logo"
                  src="nordxglobal-logo.png"
                  className="header-logo"
                />
              </Typography>
              <Box>
                <IconButton onClick={handleOpenUserMenu}>
                  <Tooltip title={user?.firstName + " " + user?.lastName}>
                    <AccountCircle
                      alt={user?.firstName + " " + user?.lastName}
                      style={{
                        // display: "flex",
                        position: "fixed",
                        right: "4%",
                        color: "white",
                        fontSize: "3.5rem",
                      }}
                    />
                  </Tooltip>
                </IconButton>
                <Menu
                  style={{
                    zIndex: "999999",
                  }}
                  classes={{ paper: "paperClass" }}
                  id="menu-appbar"
                  className="menuAccordion"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {uniqueSettings?.map((setting) => (
                    <MenuItem
                      selected={window.location.href
                        .split("-")
                        .join(" ")
                        .includes(setting.toLowerCase())}
                      key={setting}
                      onClick={handleCloseUserMenu}
                    >
                      <Button
                        style={{
                          color: window.location.href
                            .split("-")
                            .join(" ")
                            .includes(setting.toLowerCase())
                            ? "white"
                            : "#b3b3b3",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: "1.2rem",
                          textShadow: "5px 5px 5px 5px",
                        }}
                        className="menu-text"
                        onClick={(e) => handleCloseNavMenu(e)}
                      >
                        {setting}
                      </Button>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </>
          ) : (
            <>
              <Box
                style={{ color: "white", fontWeight: "bold" }}
                sx={{
                  display: { xs: "flex", md: "none" },
                  justifyContent: "flex-end",
                }}
              >
                {window.innerWidth < 677 && (
                  <IconButton
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      marginLeft: "40%",
                    }}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                  >
                    <MenuIcon
                      style={{
                        color: "#ff6101",
                      }}
                      fontSize="large"
                    />
                  </IconButton>
                )}
                <Menu
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "1.4rem",
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      style={{
                        color: "white",
                        fontWeight: "bold",
                      }}
                      selected={window.location.href
                        .split("-")
                        .join(" ")
                        .includes(page.toLowerCase())}
                      key={page}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography
                        style={{
                          color: window.location.href
                            .split("-")
                            .join(" ")
                            .includes(page.toLowerCase())
                            ? "white"
                            : "#b3b3b3",
                          fontWeight: "bold",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: "1.2rem",
                        }}
                        className="menu-text"
                      >
                        {page}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Typography
                className="header-image-container"
                variant="h6"
                noWrap
                component="div"
                onClick={() => navigate("/")}
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
              >
                <img
                  height={210}
                  width={230}
                  alt="nordxglobal-logo"
                  src="nordxglobal-logo.png"
                  className="header-logo"
                />
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "center",
                }}
                style={{
                  justifyContent: "center",
                }}
              >
                {pages.map((page) => (
                  <Button
                    className="header-titles"
                    style={{
                      color: window.location.href
                        .split("-")
                        .join(" ")
                        .includes(page.toLowerCase())
                        ? "black"
                        : "#b3b3b3",
                      fontWeight: "bold",
                      background: window.location.href
                        .split("-")
                        .join(" ")
                        .includes(page.toLowerCase())
                        ? "#FF6101"
                        : "transparent",
                      padding: "1rem",
                      fontSize: "1.2rem",
                      textTransform: "uppercase",
                      fontFamily: '"Tajawal", sans-serif',
                    }}
                    key={page}
                    onClick={(e) => handleCloseNavMenu(e)}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Button
                  style={{
                    color: "#FF6101",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    textTransform: "uppercase",
                    fontFamily: '"Tajawal", sans-serif',
                    letterSpacing: "0.5px",
                  }}
                  className="login-btn"
                  onClick={() => navigate("/login")}
                >
                  Login
                </Button>
                <Button
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    backgroundColor: "#FF6101",
                    letterSpacing: "0.5px",
                    fontFamily: '"Tajawal", sans-serif',
                  }}
                  className="start-trading-btn"
                  sx={{
                    my: 2,
                    color: "white",
                    fontWeight: "bold",
                    display: "block",
                  }}
                  onClick={() => navigate("/sign-up")}
                >
                  Start trading
                </Button>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
