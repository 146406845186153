import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrders } from "../../../../../redux/orders/orderSlice";
import { TablePagination } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  // hide last border
  "td,th": {
    border: 0,
  },
}));

export default function ClosedOrdersTable() {
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("closedAt");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (orderBy === "closedAt") {
      if (+b[orderBy] < +a[orderBy]) {
        return -1;
      }
      if (+b[orderBy] > +a[orderBy]) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => {
      return [el, index];
    });
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const orders = useSelector((state) => state.orders.orders);
  React.useEffect(() => {
    dispatch(getUserOrders(user.id));
  }, []);
  return (
    <div className="orders-table-root-container">
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: window.innerWidth <= 667 ? 1000 : 700,
            overflowY: "auto",
            maxHeight: 500,
          }}
          aria-label="customized table"
        >
          <TableHead style={{ borderBottom: "1px solid #1d4352" }}>
            <StyledTableRow>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
              >
                CLOSE TIME
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                ASSET
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                INVESTMENT AMOUNT
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                ENTRY PRICE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                CLOSE PRICE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                LEVERAGE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                STOP LOSS
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                TAKE PROFIT
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                PROFIT
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {stableSort(orders, getComparator(order, orderBy))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.filter((order) => !!order.closedAt)
              ?.map((order) => (
                <StyledTableRow
                  key={order.id}
                  style={{
                    background: "#07232E",
                    borderBottom: "1px solid #1d4352",
                  }}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "rgb(183 189 192)",
                      fontSize: window.innerWidth <= 667 && "0.7rem",
                    }}
                  >
                    {new Date(+order?.closedAt).toUTCString()}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: window.innerWidth <= 667 && "0.7rem",
                    }}
                  >
                    {order?.assetType}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: window.innerWidth <= 667 && "0.7rem",
                    }}
                  >
                    {order?.investmentAmount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: window.innerWidth <= 667 && "0.7rem",
                    }}
                  >
                    {!order?.openPrice ? "---" : order?.openPrice}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: window.innerWidth <= 667 && "0.7rem",
                    }}
                  >
                    {!order?.closedPrice
                      ? "---"
                      : Number(order?.closedPrice).toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: window.innerWidth <= 667 && "0.7rem",
                    }}
                  >
                    {order?.leverage}
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: window.innerWidth <= 667 && "0.7rem",
                    }}
                  >
                    {!order?.stopLoss ? "---" : order?.stopLoss}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                      fontSize: window.innerWidth <= 667 && "0.7rem",
                    }}
                  >
                    {!order?.takeProfit ? "---" : order?.takeProfit}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: order.profit.includes("-") ? "red" : "#18d818",
                      fontSize: window.innerWidth <= 667 && "0.7rem",
                    }}
                  >
                    {order?.profit}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{ background: "#131722" }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={orders?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
