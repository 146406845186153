import { Box } from "@material-ui/core";
import React from "react";
import "./index.scss";
function TermsAndConditionsPage() {
  return (
    <Box className="termsandconditions-policy-page-container">
      <Box>
        <h1 className="termsandconditions-policy-page-header">
          Terms and Conditions
        </h1>
      </Box>
      <Box className="termsandconditions-policy-page-textBox">
        <span className="termsandconditions-policy-page-span">
          These Terms and Conditions hereby regulate the relationship between
          you (hereinafter “the Client”) and NordXGlobal (hereinafter “the
          Company). The following terms and conditions apply to all of our
          applicants, account holders, and former clients. We believe in
          transparency, so we have included all of the information needed to
          make effective use of your account. Use of your NordXGlobal account
          signifies acceptance of these conditions. These Terms & Conditions
          apply to you and are binding upon you, if you participate at
          NordXGlobal. By doing so, you agree that you have read and understood
          these Terms & Conditions and you acknowledge that these Terms &
          Conditions shall apply to you. If you do not agree to any of these
          Terms & Conditions, you should stop using the Service immediately. We
          consider these Terms & Conditions to be open and fair. Should you need
          any explanation regarding these or any other part of our Service,
          please contact Customer Service. Please note that while these terms
          and conditions may be translated and presented in other languages,
          which is a service offered for convenience only. Should any of the
          terminology or content of any translated terms and conditions
          contradict those of its English version, or in any way mislead, the
          terms outlined in the English T&C document will prevail. This
          Agreement is executed between you (hereinafter “the Client”) and
          NordXGlobal (hereinafter “the Company). The card payments and billing
          services are processed by the Subsidiary company. To use or access any
          of the content or services made available by the company is to give
          express consent to acknowledge and comply with each and every term and
          condition, as set out in both this document and our Privacy
          Policy.When and where personal information is provided by the client,
          it is the responsibility of the client to ensure that all such
          information is correct, up to date and complete. The content of these
          terms and conditions may be changed, modified, amended, added to or
          taken away from at any time by NordXGlobal, without any prior notice
          being offered. By using the NordXGlobal website or accessing any of
          the content the company provides, the client expressly agrees to abide
          by all terms and conditions as set out in the document at the time of
          their visit. It is therefore the responsibility of the client to
          frequently revisit these terms and conditions, in order to note any
          important changes or additions. The terms and conditions as laid out
          in this document are valid indefinitely, although the company reserves
          the right to alter, amend and terminate any or all specific terms at
          any time. All customers must therefore regularly revisit the full
          disclosure of terms and conditions in order to ensure full compliance
          and agreement. Should a court of law with valid jurisdiction deem that
          any provision laid out in these terms and conditions be either invalid
          or void, it shall be immediately removed. This will, however, have no
          effect on the validity and governance of all other provisions
          contained within these terms and conditions. For more information or
          should you have any concerns with regard to our Terms and Conditions,
          get in touch with our service team. Drop us an email anytime at
          support@nordxglobal.com Acceptance By accepting these Terms &
          Conditions you are fully aware that there is a risk of losing money
          when trading and you are fully responsible for any such loss. You
          agree that your Participation at NordXGlobal is at your sole
          responsibility, discretion and risk. In relation to your losses you
          shall have no claims whatsoever against NordXGlobal or any partner, or
          respective directors, officers or employees. Restrictions You may only
          Participate at NordXGlobal if you are over 18 years of age. It is
          illegal to Participate at NordXGlobal if you are under 18 years of
          age. You may not Participate at NordXGlobal if you are geographically
          located in U.S.A, U.S. Territories, Canada, France, French
          Territories, Bulgaria & Israel. These countries shall be considered
          “Excluded Territories”. Trades placed from any of the Excluded
          Territories will be voided and any deposits or Trades made by persons
          who are located in such jurisdictions will be refunded. It is the
          Customer’s responsibility to know if their participation is lawful in
          their jurisdiction. Any person who is knowingly in breach of this
          section, including any attempt to circumvent this restriction, for
          example, by using a VPN, proxy or similar service that masks or
          manipulates the identification of your real location, or by otherwise
          providing false or misleading information regarding your location or
          place of residence, or by trading or wagers using the Website through
          a third party or on behalf of a third party located in an Excluded
          Territory is in breach of these Terms & Conditions. You may be
          committing fraud and may be subject to criminal prosecution. Trading
          rules NordXGlobal reserves the sole right to select and manage the
          assets that are available to clients at any one time and may modify or
          change the available list of available assets without prior notice or
          explanation. All NordXGlobal clients accept that from the moment a
          transaction has been executed, it cannot and will not be cancelled or
          reversed. Our clients therefore understand that sufficient thought and
          comprehensive checks must be carried out before going ahead with any
          trade. NordXGlobal is only to be bound by any information or
          communication exchanged between any other parties, by way of writing
          as stated in Customer Service and Customer Complaints/Dispute section.
          As such, our advice, communications and notices in general will only
          be considered valid and binding if they are presented in writing. All
          clients and customers of NordXGlobal accept that trading is inherently
          a highly speculative and potentially risky approach to investing,
          therefore the client accepts full responsibility for any outcomes and
          consequences that arise from their actions while using this website.
          Clients accept that the extremely fast-paced nature of certain types
          of FOREX makes it possible to both win or lose significant sums of
          money in very short periods of time. It is the responsibility of the
          client to seek independent advice should it be required, as a means by
          which to facilitate safe and responsible trading. In the instance that
          a customer wishes to arrange a limited liability transaction, this
          must be discussed and agreed with the company ahead of time and a
          formal written agreement provided and signed upon by both parties. The
          transaction must not be processed until this requirement is satisfied.
          Limited liability transactions place limits on the total losses that
          can be incurred, which differs from standard margined transactions
          where loss limits are not predetermined. It is important to know,
          however, that while loss may be predetermined, it is still possible to
          lose the agreed limit in a short period of time. Limited liability
          transactions must be comprehensively understood and discussed with the
          company before being processed. NordXGlobal liability in instances
          where damages incurred result in compensation payments will be
          strictly limited to the total amount that the client in question has
          deposited in their account, subject to that client proving their case.
          Under no circumstances will the company be held liable for additional
          damages such as loss of profits, regardless of the cause of the
          incurred loss. Clients accept that the company will not accept any
          liability whatsoever for any obligational breach or default on the
          part of depositaries, clearing houses, exchanges, market operators,
          custodians, sub-custodians, banks, brokers or counterparties with whom
          you conduct business of any kind, as well as force majeure scenario.
          Unless loss or damage is incurred by our clients directly as a result
          of our negligence or fraud, the company will not be held liable for
          losses suffered as a result of using the NordXGlobal website. Clients
          are obliged to pay NordXGlobal any and all outstanding charges and
          commissions upon demand, which we may request at any time either in
          part or in full, in order to satisfy outstanding debts. In any
          instances where owed are not repaid as required, we may begin higher
          level debt collection procedures which will result in all attached
          costs and legal fees incurred being passed on to the client in
          question. When such payments are requested the client is obliged to
          make the payment by the end of the same working day, with payments
          being made in the specified currency and to the specific bank account
          as we, the company, specify. By using the sites and services of
          NordXGlobal, you expressly agree that any and all charges and expenses
          arising in connection with payments owed represent the sole
          responsibility of you, the client – our company and its employees will
          not be held liable under any legitimate circumstances. This clause
          will be applicable even after termination of any Agreement between
          yourself and the company. NordXGlobal members are limited to placing
          no more than one trade every 2 minutes on an option that features the
          same asset, the same expiring time and the same direction. Contract
          closures are also only allowed once per contract. The company reserves
          the right to take immediate and extensive action against members
          suspected of seeking to or exploiting technical problems which may
          gain them with an unfair advantage. Where proven, all such traders
          will have their accounts frozen and be permanently banned from using
          NordXGlobal. For reference purposes, any indication of time referenced
          at any time anywhere on the NordXGlobal website refers to standard
          Greenwich mean time (GMT). Intellectual property across the
          NordXGlobal website including but not limited to software, graphic
          interface, user interfaces, methods of doing business, algorithms,
          techniques, modules, copyrights, trademarks, patents, information,
          written content, visuals, training materials remain the sole property
          of the company and therefore may not be used or borrowed by
          unauthorized persons for any purpose whatsoever. Customers with valid
          memberships are provided with the conditional right to use NordXGlobal
          intellectual property as made available via the company’s primary
          website for personal use. All such use is strictly non-transferable.
          Under no circumstances must any of the intellectual property presented
          be transferred or duplicated without written consent from NordXGlobal.
          In addition, material presented by the website must not be removed,
          edited or changed in any way. The company will not be held liable for
          any costs, expenses, or losses whatsoever incurred, or suffered by
          NordXGlobal customers, unless the loss occurs as the direct result of
          our willful neglect, fraud or negligence. In any other instances,
          NordXGlobal accepts no responsibility for direct or indirect loss or
          damage incurred as a result of using the content and services of the
          NordXGlobal website, any negative outcomes experienced as a result of
          errors or discrepancies made in the process of placing orders or
          misinterpretation of any information, or content published anywhere on
          our website. By utilizing the company’s website and services, you
          indemnify NordXGlobal and its employees of any expenses or costs
          incurred by you the customer which occur as a consequence of any given
          actions, omissions or errors by you. In the event of a force majeure
          scenario, NordXGlobal will not be held liable for any loss, damage or
          inconvenience suffered due to circumstances beyond the control of the
          company. All clients accept that trading represents a fundamentally
          risky form of investment and therefore, should only be approached and
          utilized by those with the relevant knowledge, experience and
          financial background. Volatility can be extremely high and the
          potential for facing heavy losses in a very short period of time is
          often high. It is perfectly possible for the entirety of the funds
          within an account to be lost instantaneously, even if the asset in
          question swings just fractionally in the wrong direction at the wrong
          time. It is, therefore, imperative that traders only ever invest
          disposable capital the likes of which is not dependent upon for
          facilitating a basic lifestyle. With outcomes being comprehensively
          impossible to predict accurately 100% of the time, it is important to
          ensure that your business and your life in general will not be
          detrimentally impacted if you are unsuccessful in any given trade. As
          such, NordXGlobal customers confirm that all capital used for
          investment purposes is risk capital and therefore total loss with any
          investment will not detrimentally affect their lives in any way.
          Clients fully acknowledge the fact that each and every investment
          presents a relatively high risk of loss and that in the event of loss
          the clients and only the client bears full responsibility. Individuals
          who do not have sufficient risk capital to invest in such ventures
          must not use the site and services of NordXGlobal. Clients agree that
          NordXGlobal reserves the right to round-off the last digit of asset
          and option prices. In addition, option price adjustments will be made
          by NordXGlobal in accordance with any adjustments made to the prices
          of base assets. Pricing: The Company will quote prices at which it is
          prepared to deal with the Client. Save where: A) The Company exercises
          any of its rights to close out a Transaction; or B) A Transaction
          closes automatically, it is Client’s responsibility to decide whether
          or not he wishes to deal at the price quoted by the Company. Company’s
          prices are determined by the Company in the manner set out in the
          enclosed terms. Each price shall be effective and may be used in a
          dealing instruction prior to the earlier of its expiration time and
          the time, if any, at which it is otherwise withdrawn by the Company. A
          price may not be used in a dealing instruction after such time. Each
          price shall be available for use in a dealing instruction for a
          transaction with a principal amount not to exceed a maximum determined
          by the Company. The Client acknowledges that these prices and maximum
          amounts may differ from prices and maximum amounts provided to other
          customers of the Company and may be withdrawn or changed without
          notice. The Company may in its sole discretion and without prior
          notice to the Client immediately cease the provision of prices in some
          or all currency pairs and for some or all value dates at any time.
          When the Company quotes a price, market conditions may move between
          Company’s sending of the quote and the time the Client’s order is
          executed. Such movement may be in Client’s favor or against it. Prices
          that may be quoted and/or traded upon, from time to time, by other
          market makers or third parties shall not apply to trades between the
          Company and the Client. The prices generated by our trading
          platform(s) are derived from the prices of the relevant underlying
          instruments, which the Firm obtains from third party liquidity/ price
          providers. The prices of Assets that you trade with us include a
          mark-up; this means that the spreads offered by us comprise of (i) the
          raw spreads received from liquidity/ price provider(s) and (ii) a
          mark-up (where applicable). The fees, commissions, SWAPs or spreads
          are subject to change due to market volatility or other risk factors.
          We reserve the right to change any of the charges applicable to your
          dealing with us. We charge our own SWAP interest rates. We update them
          as necessary. Without prejudice to any other rights of ours, or the
          law, we make may take the following course of action(s): a) Inform
          you, where we have sufficient time to do so in the circumstances; b)
          Increase margin requirements / decrease leverage; c) Increase/ change
          spreads; d) Close any Open Position(s) at the price available in the
          circumstances, which may include: a. Combine or close any open
          positions at ‘Volume-Weighted Average Price’ (‘VWAP’); b. Request
          amendments to any closed position(s) e) Suspend, limit or restrict the
          provision of our services to you; f) Amend any part of the Agreement
          on the basis that it is no longer feasible for us to comply with it;
          g) Cease trading; h) Precluding you from accessing or using the
          Software or any other system; i) Make any necessary amendments to open
          trades; j) Allow close-only functionality; k) Reject or delay the
          processing of any withdrawal request from your Account(s) l) Impose
          special or different terms regarding any of your orders in relation to
          size, volatility and/or liquidity of the instrument, amongst others;
          m) Remove or temporarily suspend any products, or change any contract
          specifications; Improper or Abusive Trading is executing trading
          strategies acting in bad faith, that is, you or any representative of
          yours, trading on your behalf, is committing any improper or abusive
          trading act such as, for example: fraud/illegal actions that led to
          the transaction; orders placed based on manipulated prices as a result
          of system errors or system malfunctions; arbitrage trading on prices
          offered by our platforms as a result of system errors; and/or
          coordinated transactions by related parties in order to take advantage
          of system errors and delays on systems updates. Then the Company will
          have the right to: i. adjusts the price spreads available to you;
          and/or ii. restrict your access to streaming, instantly tradable
          quotes, including providing manual quotation only; and/or iii. obtain
          from your account any historic trading profits that you have gained
          through such abuse of liquidity as determined by us at any time during
          our trading relationship; and/or iv. reject an order or to cancel a
          trade; and/or v. immediately terminate our trading relationship.
          Should any NordXGlobal customer violate any obligations laid out in
          these terms and conditions, become bankrupt or insolvent, or in the
          event that the company deems it necessary for its own protection,
          NordXGlobal may at any time and without prior notice: Sell, pledge, or
          transfer the full balance of the account and any remaining securities;
          Close, terminate or cancel any or all transactions still open; Set off
          any damages caused to it; Take the required action in instances where
          client violations must be remedied. Promotion Terms NordXGlobal
          reserves the right to promote and advertise special offers, services,
          products and general data from any third-party providers as deemed
          appropriate. Where such promotional listings, or links appear, they
          are provided strictly on a casual and impartial basis and do not
          necessarily represent direct suggestions or advice from NordXGlobal .
          As such, the company will not be held responsible for any outcomes or
          eventualities resulting from pursuing advertise promotions or
          following any links published on the NordXGlobal website. NordXGlobal
          reserves the right to withdraw any promotion, competition, bonus or
          special offer at any time. Customer offers are limited to one per
          person, family, household address, email address, telephone number,
          same payment account number (e.g. debit or credit card and etc.),
          shared computer (e.g. school, public library or workplace) and shared
          IP. All NordXGlobal bonuses and promotions are intended for
          recreational players. NordXGlobal may, in its sole discretion, limit
          the eligibility of Customers to participate in all, or part of any
          promotion. NordXGlobal reserves the right to request a signed Bonus
          Agreement Form, prior to crediting a bonus to a Customer’s account. If
          you wish to find out how much rollover requirements remain in respect
          of a given bonus, please contact support@nordxglobal.com Bonus Policy
          NordXGlobal offers a number of attractive reward features to its new
          and regular customers. Bonuses and one-time trading credits rewarded
          to customers are part of NordXGlobal's promotions program. These
          bonuses are limited-time offers and the terms and conditions
          associated with any bonus rewards are subject to change. In order to
          be able to withdraw your funds, you will be required to execute a
          minimum trading volume of 30 times for every 1-unit bonus Risk free
          trading – the maximum withdrawable winnings from Risk free, credited
          as a result of a single promotion, shall be 50 Units. The funds can be
          withdrawn when the preceding stipulations have been fully met and
          fulfilled. The withdrawal of funds from an account can be completed
          before the bonus conditions have been satisfied, with an applicable
          penalty depending on the existing trading positions and bonus amount
          given as well as cutting all gained profits. Any indication of fraud,
          manipulation, cash-back arbitrage, or other forms of deceitful or
          fraudulent activity based on the provision of the bonus will nullify
          the account, including any/all profits or losses garnered. Clients
          acknowledge and accept the fact that in accordance with legal
          regulations and requirements, NordXGlobal may freeze or restrict
          accounts, impose limitations on services offered, deduct tax payments
          of any kind and to any extent or take whatever action may be required,
          should we be instructed to do so by the relevant authorities.
          NordXGlobal also accepts no liability whatsoever for any loss, damage
          or detrimental consequences suffered as a result of any action taken
          by the company, in accordance with the express instructions of legal
          authorities, regulators or financial institutions. No warranties The
          Service, Offering and the Website are provided on an “as is” basis and
          to the fullest extent permitted by law, we make no warranty or
          representation, whether express or implied, in relation to the
          satisfactory quality, fitness for purpose, completeness or accuracy of
          the Service, Offering or Website. Your Account Single Account All
          NordXGlobal customers are prohibited from having more than one account
          open at any one time. Should the company detect multiple accounts are
          active under the same name, any or all of the accounts may be
          immediately frozen and closed down without prior notice given.
          Accounts will also be closed in instances where traders use assumed or
          fraudulent names, or link their personal membership accounts to
          payment methods and bank accounts that are not their own. NordXGlobal
          members are permitted to open and maintain only one active account,
          with which investments must be made using their own money. 2.
          Transfers between accounts on the platform are forbidden. 3. Accuracy
          You are required to keep your registration details up to date at all
          times. If you change your address, e-mail, phone number or any other
          contact or personal information, please contact
          support@nordxglobal.com in order to update your account information.
          The name that you provide NordXGlobal at registration must be
          identical to that listed on your government issued identification. 4.
          As the client of NordXGlobal, you immediately and comprehensively
          agree that: You are able to confidently and comprehensively commit to
          each and every requirement laid out in these terms and conditions,
          along with other key NordXGlobal documentation including our Privacy
          Policy. You hereby agree to take all action necessary to adhere to
          these terms and conditions. You are able to both use the services of
          NordXGlobal and function in accordance with our terms and conditions
          in full, without violating any laws applicable to you at the time. You
          are instead legitimately permitted and authorized to operate as an
          NordXGlobal account holder. All information submitted by you at any
          time is 100% accurate, complete and true in every regard. You will
          also ensure that in any instance where your personal information
          changes or becomes no longer valid, it is your responsibility to
          immediately amend the required data, or to contact the NordXGlobal
          customer service team to organize the necessary changes. Your account
          should be properly updated with the new personal data no later than 14
          days after you become aware of the changes. Any regulatory or
          governmental permissions required in order for you to legitimately
          access and use the services of NordXGlobal have been obtained and are
          in full and active effect at the time of your using this site. When
          any disclosure requirements, regulations, rules or laws apply as
          imposed and laid out by any regulatory authority, exchange or
          jurisdiction, you fundamentally agree to comply with them Should we at
          any time request any further information from you or any additional
          verification of your identity or personal data, you will provide us
          with the required information and methods of identification as quickly
          as possible. In the instance that you fail to do so, your account may
          be suspended indefinitely. All NordXGlobal customers act as a
          principle in all transactions carried out on the NordXGlobal website.
          Should you believe or know that you are in any way contravening any of
          the terms laid out in these terms and conditions, it is your
          responsibility to inform NordXGlobal as soon as possible. 5.
          Username/Password The Customer account registration process requires
          you to choose your own user name and password combination. You must
          keep this information secret. Any actions carried out through your
          account will stand if your user name and password have been entered
          correctly. NordXGlobal can bear no responsibility for unauthorised use
          or misuse of personal details. 6.Verification of Bank Details If you
          use a credit/debit card and/or a financial/bank account for
          transactions at NordXGlobal, the account/card holder’s name MUST be
          the same as the name you used when registering a NordXGlobal account.
          Should the name you registered on the NordXGlobal account and the name
          that appears on your credit/debit card and/or financial/bank account
          differ in any way, your account will be immediately suspended. Should
          your account be suspended, we recommend that you contact Customer
          Service for details regarding our verification process. 7. No
          liability Customers of NordXGlobal fully accept the possibility that
          technical difficulties and temporary issues may affect the ability of
          account holders to execute actions, access features and generally
          utilize the website. NordXGlobal therefore accepts no liability for
          any problems or consequences resulting from any such events at any
          time, which may be attributed to software issues, bandwidth, servers,
          hardware issues or anything else of a similar nature. All clients
          comprehensively accept and acknowledge the fact that executing deals
          and trades by way of an Internet connection inherently carries certain
          risks that cannot be fully avoided or prevented. Internet connectivity
          issues, hardware failure, poor signal strength, loss of power and
          other such issues can all result in delayed access, discrepancies and
          general failures when looking to access and utilize the services
          available by NordXGlobal. Therefore, the company cannot and will not
          be held responsible for any such issues, or resulting consequences.
          Clients acknowledge the fact that even when utilizing the most
          reliable technology and software available, it is fundamentally
          impossible to 100% rule out a variety of technical problems and
          general issues that may temporarily make it difficult or even
          impossible to access your NordXGlobal account. As such, the company
          will not be held liable in the event of any loss or inconvenience that
          may occur due to standard and non-standard issues, including, but not
          limited to hardware defects, unauthorized access, security breaches,
          periods of high Internet traffic, issues with system capacity,
          temporary system malfunctions and failures, software issues, hacking
          attacks and so on. To use NordXGlobal is to accept the fact that we do
          not and will not at any time guarantee that you will be able to access
          part or the entirety of our website, or your trading account. We
          cannot and will not guarantee flawless, error-free and 100%
          uninterrupted service at any time. In no instances, therefore shall we
          be held liable for any losses incurred as a result of your inability
          to access your account or the NordXGlobal website. 8. NordXGlobal
          customers accept that any/all telephone conversations may be recorded
          for quality and training purposes, without the caller being informed.
          All recorded conversations may be held in storage and used at later
          junctures in the event of disputes, or should their content be
          required by relevant authorities upon request. NordXGlobal promises to
          destroy both recordings and transcripts after three years 9. All
          communications and notices permitted and required hereunder to be
          given to clients and customers, covered by these terms and conditions,
          will be provided in writing. Clients are provided with notices and
          important information by way of the contact details provided at the
          time the account is opened – all of which must be verified as accurate
          and complete. All notices and correspondence sent by NordXGlobal to
          the customers will be recorded as delivered at the exact time the
          message was delivered if a) delivered by hand, b) sent by email or c)
          sent by fax. All messages and communications sent by postal mail will
          be recorded as delivered five days after the date of postage. In the
          instance of correspondence sent to a client’s company, the message
          will be considered delivered when written confirmation is provided by
          the recipient. 10. Expiry rates are computed in accordance with Bid &
          Ask prices ((Bid + Ask)/2) which represent the closest price offers to
          the specified time of expiration. NordXGlobal will round the last
          digit of the price down if the last digit is below 5 and up if it is 5
          or above. Identification Documentation 1. Participating in NordXGlobal
          requires you to enter your personal details during the account
          registration process. Personal details include, but are not limited
          to: first name, surname, address, date of birth etc. The ‘Know Your
          Client’ (KYC) procedure will be carried out when a Customer makes a
          deposit into their Customer account. 2. Upon making a withdrawal
          request you may also be required to send in valid identification
          documents proving your age and address. Upon such request, the
          withdrawal will not be processed for payment until NordXGlobal has
          received all requested identification documents. Acceptable
          identification documentation includes, but is not limited to: Color
          copy of a valid government-issued photo ID (e.g. passport, driving
          license or ID card). Color copy of a utility bill (e.g. water,
          electricity, or gas) or bank statement. This document should contain
          your name, address, and date of issue (must be no older than 3 months)
          clearly visible. Color copy of the front side of the credit/debit card
          used for the account deposit. Please make sure first 6 and last 4
          digits of the bank card number are clearly visible, along with the
          name and date of expiry. For your own security, please hide the middle
          digits. Color copy of the back side of the credit/debit card used for
          the account deposit. Please make sure the first 6 and last 4 digits of
          the credit card number are clearly visible, along with the name, date
          of expiry and signature (please make sure to sign the back of the
          credit card on the appointed signature strip). For your own security,
          please hide the middle digits and the CVV code (the 3-digit security
          code). A signed copy of the Declaration of Deposit (DOD) 3. Failure to
          produce such documentation within 7 days after first deposit will
          result in suspension of your account and all membership privileges on
          our site. Inactive Accounts Inactivity Fee – NordXGlobal will consider
          an account to be dormant/inactive after a period of 3 months from the
          last account login. Once your Customer account has been designated as
          a Dormant account, NordXGlobal shall be entitled to charge you a
          monthly administration fee of 50 Units The fee will be deducted
          directly from each client’s trading account, from their available
          balance. To avoid this 50 Units inactivity charge, a client must log
          in to their trading account at least once every three (3) months.
          Clients whom have never deposited money in their trading account will
          not be charged an inactivity fee. Clients with less than a 50 Units
          balance in their trading account will be charged that balance amount,
          thus bringing their account to zero. Suspended accounts are still
          liable to pay the inactivity fee, if conditions arise. Closed/disabled
          accounts are not charged inactivity fees. In any instance where a
          customer’s account remains inactive for a period of 12 months,
          NordXGlobal reserves the right to close the account upon providing the
          customer with a written notice, five days ahead of the account’s
          closure. NordXGlobal may at any time limit or cease any or all access
          to trading activities on the company’s website without the requirement
          to provide notice in advance. Should any such restrictions or
          withdrawals be implemented, the company will not be held liable or
          responsible for any costs, damages or negative consequences that may
          arise for its customers. Deposits and Withdrawals 1. Identification
          Checks NordXGlobal must successfully receive and verify your
          identification documents, either via directly by the trading account
          holder or a third party, before you can make a Withdrawal from your
          account. 2. Credit Checks NordXGlobal reserves the right to run
          external verification checks on all cardholders with third party
          credit agencies, on the basis of the information provided on
          registration. 3. Records It is the cardholder’s responsibility to
          retain copies of transaction records and these Terms & Conditions as
          updated from time-to-time. Deposits 1. No third-party deposits are
          allowed. 2. The minimum deposit amount and the processing time that
          deposits take, are dependent on the payment method selected, as shown
          in Deposit page. Further, these time frames are indicative of the
          regular timescale for deposit confirmation in business days and
          represent estimates only. 3. In accordance with our anti-money
          laundering obligations, we reserve the right to raise queries or
          demand documentation related to the source of the deposited funds. If
          the provided information and/or documentation is assessed as
          unsatisfactory, NordXGlobal may suspend/terminate the Customer’s
          account and pass on any necessary information to the relevant
          authorities. 4. Updating Payment Details. Updating or adding
          additional payment details for the sole purpose of making a withdrawal
          may only be done by contacting support@nordxglobal.com Error: Should
          funds be credited to a Customer’s account or credited to a financial
          account and/or credit/debit card in error, it is the Customer’s
          responsibility to notify NordXGlobal of the error without delay. Any
          winnings subsequent to the error shall be deemed invalid and returned
          to NordXGlobal. We reserve the right to withhold all or part of your
          balance and/or recover from your account deposits, pay outs, bonuses,
          and any winnings that are attributable to said error. Refunds: Refunds
          may be given in case of exceptional circumstances and shall be given
          solely at the discretion of NordXGlobal. Refund Policy- Clients can,
          at any time withdraw, or receive a refund. All Withdrawals and Refunds
          are under the conditions of our Verification policy and Bonus policy.
          It is therefore the right of the company to refuse a withdrawal of
          customer funds, IF said requirements have not been met. The company,
          can from time to time decide to allow a withdrawal, if it deems fit to
          do so, but is under no obligation to fulfil this request if the
          customer agrees to the terms and conditions prior to depositing.
          Withdrawals 1. Withdrawals of funds are subject to fees depending on
          the payment method used. Clients agree that as a result of their
          withdrawal request 20 Units will be deducted from the withdraw amount,
          if the withdrawal is requested directly to a bank account. 2. The
          Minimum withdrawal amount for bank wire transfer is 500 Units. 3. The
          Minimum withdrawal amount for credit card transfer is 50 Units. 4.
          When a client issues a request for a withdrawal, the process will be
          initiated within 1-3 business days and will result in the funds being
          transferred by way of: A refund to the specified withdrawal amount to
          the credit or debit card from which the member’s account was credited
          in the first place. Only the original card used to make the initial
          deposits and a card matching the name of the account holder may be
          used. Bank wire transfer to the bank account of the account holder,
          which again must be the same account from which initial deposit
          payments were made. The minimum withdrawal amount and the time that
          the withdrawal will take to process, are dependent on the payment
          method selected, as shown above, these time frames are indicative of
          the regular timescale for internal pay-out confirmation in business
          days and represent estimates only. 5. The minimum withdraw request for
          gained profits on NordXGlobal platform is 500 Units and they are paid
          via bank wire transfer. 6. All withdrawals will be processed back to
          the same payment method used to make a deposit to the account. As
          outlined, if a financial account and/or credit/debit card has been
          used to deposit funds, the name registered on the Customer account
          held with NordXGlobal must correspond to the name registered on the
          financial account/card. In the case of a Direct Bank Transfer payment
          request, the acquiring bank account must be held in the same name as
          used at the time of Customer account registration. 7. Please note that
          withdrawals may experience a slight delay due to our identity
          verification process. Certain deposit methods will require additional
          verification at time of Withdrawal. In the case of a withdrawal being
          made for the first time, a large withdrawal or changes being made to
          payment options, we may take additional security measures to ensure
          that you are the rightful recipient of the funds. 8. Where applicable,
          NordXGlobal may, at its sole discretion, reverse/refund a Customer’s
          deposited funds instead of processing a withdrawal transaction via the
          same payment method. 9.Prior to authorization being given for any
          withdrawals processing, NordXGlobal may ask clients to produce
          additional proof of identity by submitting the required identification
          documents. The withdrawal will only continue after such documents have
          been produced – inability to verify identity as required will result
          in the funds being transferred back to the member’s NordXGlobal
          account. All withdrawals are subject to the standard NordXGlobal terms
          and conditions, which states that completion may require up to 14
          business days, or longer in the case of certain billing service
          providers. It is possible for a client to cancel a withdrawal should
          they wish to do so. However, the cancellation request must be made as
          quickly as possible following the initiation of the withdrawal
          request. If the request has been executed and confirmation of the
          withdrawal has been sent, the withdrawal cannot be reversed. Should
          multiple withdrawal requests be made by the client, multiple
          cancellation requests must also be made accordingly, should they wish
          to halt the process. Termination of Agreement The Company may
          terminate this Agreement at any time by giving you notice to this
          effect. You may terminate this Agreement at any time by giving the
          Company a 48 hours advanced notice. Termination of the Agreement by
          any of the Parties shall require you to close all open positions by
          executing the required transactions. As from the date of your given
          termination notice, you may not execute any new Transactions, which
          shall open new positions in the trading account. Should the company
          suspect that any kind of fraudulent activity is taking place, or that
          the system or any of its technology or flaws therein are being
          wrongfully exploited, an investigation will be launched with its
          completion expected within a 45-day period. Client accounts and
          privileges may be suspended during this time, while those found to
          have committed any fraudulent or illegitimate activities will be
          banned from NordXGlobal for life and reported to the relevant
          authorities. NordXGlobal reserves the right to sublicense, transfer,
          grant or assign its rights both in full and in part to third parties
          at any time and for any purposes. The client however has no such
          entitlement and must not carry out any of the above-mentioned
          activities under any circumstances. Privacy Policy NordXGlobal is
          committed to protecting and respecting your privacy. Further, the
          Operator complies with all applicable data protection and privacy
          laws, including Regulation (EU) 2016/679 of the European Parliament
          and of Council (“GDPR”). If you do not understand how we handle or use
          the personal information you provide us, we recommend that you review
          our Privacy Policy. Our Privacy Policy is inseparably linked to these
          Terms & Conditions and its acceptance is a prerequisite to account
          registration. You hereby consent to receive marketing communications
          from the Operator in respect of its offerings by way of email, post,
          SMS and telephone notifications, any of which you may unsubscribe from
          at any time by contacting Customer Service. NordXGlobal reserves the
          right to provide the obtained, processed, and stored data of its
          clients to third-parties (administrators or personal data operators)
          for the purpose of fulfilling its contractual obligations to its
          clients, stipulated herein, including the verification and management
          of accounts. NordXGlobal Not a Financial Institution 1. No legal or
          tax advice 2. NordXGlobal does not provide advice regarding tax and/or
          legal matters. Clients who wish to obtain advice regarding tax and
          legal matters are advised to contact appropriate advisors. 3. Any
          payments owed to the company by the client will be made immediately
          upon request by NordXGlobal in the specified currency and to the
          required bank account, at the time the payment is requested. Should
          you be required to make any payments with regard to taxes or similar
          deductions, in accordance with your jurisdiction, you will be required
          to pay the required amount to us in order to ensure that we receive
          the net amount in accordance with the full amount payable, if no
          deductions have been applied. Payments owed to NordXGlobal may be
          collected at any time without prior notice. In any instances where
          taxes, registration fees, transfer fees, commissions or administration
          fees of any kind are payable, they will be paid in full by the client
          and not by NordXGlobal. 4. You are solely responsible for any
          applicable taxes on any prizes and/or winnings that you collect from
          NordXGlobal. No arbitrage You are strictly prohibited from utilizing
          NordXGlobal and its systems to facilitate arbitrage through currency
          exchange transactions. Where NordXGlobal deems that you have
          deliberately used the systems for financial gain through arbitrage,
          any gains will be forfeited and deducted from your balance without
          warning or notification. Anti-Money Laundering (AML) Clients are
          strictly prohibited from using NordXGlobal and its systems to
          facilitate any type of illegal money transfer. You must not use the
          Website for any unlawful or fraudulent activity or prohibited
          transaction (including money laundering proceeds of crime) under the
          laws of any jurisdiction that applies to you. If NordXGlobal suspects
          that you may be engaging in, or have engaged in fraudulent, unlawful
          or improper activity, including money laundering activities or any
          conduct which violates these Terms & Conditions, your access to
          NordXGlobal will be terminated immediately and your account may be
          blocked. If your account is terminated or blocked under such
          circumstances, NordXGlobal is under no obligation to refund to you any
          money that may be in your account. In addition, NordXGlobal shall be
          entitled to inform the relevant authorities, other online service
          providers, banks, credit card companies, electronic payment providers
          or other financial institution of your identity and of any suspected
          unlawful, fraudulent or improper activity. You also agree to cooperate
          fully with any NordXGlobal investigation into such activity.
          Termination/Suspension of Account NordXGlobal hereby reserves the
          right to cancel your account for any reason whatsoever at any time
          without notifying you. Any balance in your account at the time of such
          a cancellation will be credited to your credit/debit card or financial
          account. We hereby reserve the right, at our sole discretion, to
          cancel or suspend your account (notwithstanding any other provision
          contained in these Terms &Conditions) where we have reason to believe
          that you have engaged or are likely to engage in any of the following
          activities: If you have more than one active account at NordXGlobal ;
          If the name registered on your NordXGlobal account does not match the
          name on the financial/bank account and/or the credit/debit card(s)
          used to make deposits on the said NordXGlobal account; You become
          bankrupt; If you provide incorrect or misleading information while
          registering a NordXGlobal account; If you attempt to use your Client
          account through a VPN, proxy or similar service that masks or
          manipulates the identification of your real location, or by otherwise
          providing false or misleading information regarding your citizenship,
          location or place of residence, or by trading or wagers using the
          website through a third party or on behalf of a third party; If you
          are not over 18 years old; If you are located in the Excluded
          Territories; If you have allowed or permitted (whether intentionally
          or unintentionally) someone else to participate using your NordXGlobal
          account; If you have played in tandem with other Client(s) as part of
          a club, group, etc., or trade or wagers in a coordinated manner with
          other Client(s) involving the same (or materially the same)
          selections; If NordXGlobal has received a “charge back” and/or a
          "recall" notification via a deposit mechanism used on your account; If
          you are found to be colluding, cheating, money laundering or
          undertaking any kind of fraudulent activity; or If it is determined by
          NordXGlobal that you have employed or made use of a system (including
          machines, computers, software or other automated systems such as bots)
          designed specifically to defeat the gaming system. If NordXGlobal
          closes or suspends your Client account for any of the reasons referred
          to in section above, you shall be liable for any/all claims, losses,
          liabilities, damages, costs and expenses incurred, or suffered by
          NordXGlobal (altogether “Claims”) arising therefrom, shall indemnify
          and hold NordXGlobal financially and legally unharmed on demand for
          such Claims. If we have reasonable grounds to believe that you have
          participated in any of the activities set out in section 1 above then
          we reserve the right to withhold all or part of the balance and/or
          recover from your account deposits, pay-outs, bonuses, any winnings
          that are attributable to any of the practices contemplated in clause
          2. In such circumstances, your details will be passed on to any
          applicable regulatory authority, regulatory body or any other relevant
          external third parties. The rights set out here are without prejudice
          to any other rights that we may have against you under these Terms &
          Conditions or otherwise. Customer Service and Customer Complaints /
          Dispute 1. Customer Service You may contact Customer Service at any
          time by the following means: 1. E-mail: support@nordxglobal.com 2.
          Telephone: +442080683981 3. LiveChat 2. Submission of Complaint
          Dispute Customer complaints/claims of any nature must be submitted
          within 1 month of the issue occurring. In order to ensure your
          complaint/claim is directed to and investigated by the correct
          department, written communication must be submitted to NordXGlobal via
          the following mean: E-mail: support@nordxglobal.com 3. Information
          which Must be Included in any Written Communication with NordXGlobal
          To protect your privacy, all email communications between you and
          NordXGlobal should be carried out using the email address that you
          have registered with your Client account held with NordXGlobal.
          Failure to do so may result in our response being delayed. The
          following information must be included in any written communication
          with NordXGlobal (including a Complaint): your username; your first
          name and surname, as registered on your Client account; a detailed
          explanation of the complaint/claim; and any specific dates and times
          associated with the complaint/claim (if applicable). Please note that
          any failure to submit written communication with the information
          outlined above may delay the identification and response process of
          your complaint/claim. Upon receipt, we will endeavor to reply to your
          communication within 3 business days. Further, best efforts will be
          made to resolve any reported matter promptly and, at a maximum, within
          one month. 4. Chargeback / Dispute Fees NordXGlobal reserves the right
          to charge an administrative fee of the total Chargeback made by the
          individual, that may be levied by any institution. 5. Collusion Should
          you ever suspect that a Client is colluding with another Client or
          cheating in any way, please notify NordXGlobal via the means of
          communication listed in the above Customer Complaints procedure (set
          out in section 1). Company: Entropic Labs Ltd | Company at Ground
          Floor, The Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet,
          Saint Lucia.
        </span>
      </Box>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Box>
  );
}

export default TermsAndConditionsPage;
