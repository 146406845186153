import * as React from "react";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import "./index.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
export default function DateTimePickerComponent({
  date,
  setDate,
  label,
  isEditUser,
  setDisableSaveButton,
}) {
  //   const [value, setValue] =
  //     (React.useState < Dayjs) | (null > dayjs(new Date().toISOString()));
  //   const [value, setValue] = React.useState(dayjs(new Date().toISOString()));

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          views={["year", "month", "day", "hours", "minutes", "seconds"]}
          sx={{
            margin: !!isEditUser ? "0.6rem !important" : "12px 2px",
          }}
          className="date-picker-styles"
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          label={label}
          value={date}
          onChange={(newDate) => {
            !!isEditUser && setDisableSaveButton(false);
            setDate(new Date(newDate).getTime());
          }}
        />
      </LocalizationProvider>
    </>
  );
}
