import React from "react";
import { useSelector } from "react-redux";
import { AdvancedChart } from "react-tradingview-embed";

const EmbededChart = () => {
  const state = useSelector((state) => state.cryptoMarketDataReducer);
  const mobileWidth = window.innerWidth;

  return (
    <div id="tvchart" style={{ position: "relative", zIndex: "99999" }}>
      <AdvancedChart
        widgetProps={{
          timezone: "Etc/UTC",
          theme: "dark",
          style: "9",
          hide_top_toolbar: mobileWidth <= 657 ? true : false,
          withdateranges: mobileWidth <= 657 ? false : true,
          width:
            mobileWidth <= 667
              ? "100%"
              : mobileWidth >= 1050 && mobileWidth <= 1300
              ? 700
              : 900,
          height: mobileWidth <= 657 ? 500 : 540,
          locale: "en",
          toolbar_bg: "rgba(102,102,102,0.4)",
          save_image: false,
          enable_publishing: false,
          allow_symbol_change: true,
          symbol: state.symbol,
          hide_side_toolbar: mobileWidth <= 657 ? true : false,
        }}
        widgetPropsAny={{ newProp: true }}
      />
    </div>
  );
};

export default React.memo(EmbededChart);
