import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import { getUserOrders } from "../../../../redux/orders/orderSlice";

import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { orderServices } from "../../../../services/orderServices";
import ClosedOrdersTable from "./components";
import OpenedOrdersTable from "./components/openedOrders";

function Orders({ orderType, setOrderType }) {
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const orders = useSelector((state) => state.orders.orders);

  const handleChangeOrderType = (e, type) => {
    setOrderType(type);
  };

  React.useEffect(() => {
    dispatch(getUserOrders(user?.id));
  }, [dispatch, user?.id]);

  let openOrderLength = orders?.filter((order) => !order.closedAt).length;

  return (
    <div
      style={{ marginTop: window.innerWidth <= 667 && "2%" }}
      className="ordersWrap"
    >
      <ToggleButtonGroup
        value={orderType}
        exclusive
        onChange={handleChangeOrderType}
        className="orderGroup"
      >
        <ToggleButton
          value="openOrders"
          color="primary"
          className="orderButtons"
        >
          Open Orders ({openOrderLength})
        </ToggleButton>
        <ToggleButton
          value="closedOrders"
          color="primary"
          className="orderButtons"
        >
          Closed Orders
        </ToggleButton>
      </ToggleButtonGroup>
      {orderType === "openOrders" ? (
        openOrderLength === 0 ? (
          <p style={{ color: "white" }}>No open orders...</p>
        ) : (
          <div>
            <div className="orders">
              <OpenedOrdersTable user={user} />
            </div>
          </div>
        )
      ) : (
        <div className="orders">
          <ClosedOrdersTable />
        </div>
      )}
    </div>
  );
}

export default Orders;
