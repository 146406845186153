import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userServices } from "../../services/userServices";

export const loginUser = createAsyncThunk("userLogin", async (data) => {
  try {
    return await userServices.loginUser(data);
  } catch (error) {
    throw new Error(error);
  }
});

export const loginUserAdmin = createAsyncThunk(
  "userLoginAdmin",
  async (data) => {
    try {
      return await userServices.loginUserAdmin(data);
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const getUserAvailableClients = createAsyncThunk(
  "getUserAvailableClients",
  async (userId) => {
    try {
      return await userServices.getUserClientsById(userId);
    } catch (e) {
      throw new Error(e);
    }
  }
);
export const changeOnlineActivity = createAsyncThunk(
  "onlineActivity",
  async (id, isOnline) => {
    try {
      return await userServices.changeOnlineActivity(id, isOnline);
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const loginUserAuto = createAsyncThunk("userLoginAuto", async (data) => {
  try {
    return await userServices.autoLoginUser(data);
  } catch (error) {
    throw new Error(error);
  }
});

export const getUser = createAsyncThunk("getUser", async (userId) => {
  try {
    return await userServices.getUser(userId);
  } catch (e) {
    throw new Error(e);
  }
});

export const getUserBalance = createAsyncThunk(
  "getUserBalance",
  async (userId) => {
    try {
      return await userServices.getUserBalance(userId);
    } catch (e) {
      throw new Error(e);
    }
  }
);
export const deleteUser = createAsyncThunk("deleteUser", async (userId) => {
  try {
    return await userServices.deleteUser(userId);
  } catch (e) {
    throw new Error(e);
  }
});
export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      foundUser: null,
    },
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(loginUserAuto.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user.foundUser = action.payload;
      })
      .addCase(getUserAvailableClients.fulfilled, (state, action) => {
        state.user.foundUser.availableUsers = action.payload;
      })
      .addCase(getUserBalance.fulfilled, (state, action) => {
        state.user.foundUser.ballance = action.payload.ballance;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.user = null;
      });
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;

export const isAuth = (state) => state?.state?.user;
