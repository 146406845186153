import React, { useState } from "react";
import { useNavigate } from "react-router";

import "./index.scss";
import { userServices } from "../../services/userServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ForgottenPasswordPage() {
  const [email, setEmail] = useState();
  const handleForgotPasswordFlow = async () => {
    await userServices.postForgotPassword({ email }).then((res) => {
      setEmail("");
      toast.success(`${res.data}`, {
        position: "top-center",
        hideProgressBar: false,
        autoClose: true,
        closeOnClick: true,
        pauseOnHover: true,
        toastId: 1232,
      });
    });
  };
  return (
    <>
      <div>
        <div className="forgotten-container">
          <div className="forgotten-panel-forgotten-pass">
            <h2 style={{ color: "#989898" }}>
              Enter the email address you registered with!
            </h2>
            <form action="" method="post" className="forgotten-form">
              <div className="forgotten-panel-group">
                <input
                  className="forgotten-input"
                  type="text"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    color: "#A6A6A6",
                    backgroundColor: "#151515",
                    border: "1px solid #A6A6A6",
                  }}
                />
                <span className="forgotten-border"></span>
                <label style={{ color: "#A6A6A6", marginTop: "-1%" }}>
                  Email Address
                </label>
              </div>

              <button type="button" onClick={() => handleForgotPasswordFlow()}>
                Send request
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ForgottenPasswordPage;
