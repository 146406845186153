import { Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubNavbar from "../../components/SubNavbar";
import { getUser } from "../../redux/users/userSlice";
import { userServices } from "../../services/userServices";
import "./index.scss";

function SecurityTradePage() {
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const dispatch = useDispatch();
  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newRepeatedPassword, setNewRepeatedPassword] = React.useState("");

  useEffect(() => {
    //     let socket = io('/security-trading');
    // socket.on('message from server', (msg) => {
    //   console.log(msg);
    // })

    dispatch(getUser(user.id));
  }, []);

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (newPassword === newRepeatedPassword) {
      userServices
        .changeUserPassword(user?.id, { password: newRepeatedPassword })
        .then(() => {
          dispatch(getUser(user?.id));
          alert("You have successfully changed your password!");
        });
    } else {
      alert("The passwords do not match!");
    }
  };

  const onHandleChange = (e) => {
    e.preventDefault();

    switch (e.target.name) {
      case "password":
        setPassword(e.target.value);
        return;
      case "newPassword":
        setNewPassword(e.target.value);
        return;
      case "newRepeatedPassword":
        setNewRepeatedPassword(e.target.value);
        return;
      default:
        break;
    }
  };
  return (
    <div className="security-trading-page-root-container-parent-root">
      <SubNavbar></SubNavbar>
      <div className="security-trading-page-root-container-parent">
        <div className="security-trading-page-root-container">
          <div className="security-trading-page-security-trading-info-wrapper">
            <div className="security-trading-page-container-header">
              <h1>SECURITY</h1>
            </div>
            {/* <div
              className="security-password-container"
              style={{
                backgroundColor: "#0a5f76",
                color: "white",
              }}
            > */}
            {/* <TextField
              name="password"
              fullWidth
              onChange={(e) => onHandleChange(e)}
              variant="filled"
              placeholder="Current Password"
              value={user.password}
              type="password"
              style={{ marginBottom: "12px", backgroundColor: "white" }}
            >
              {password}
            </TextField> */}

            <TextField
              variant="filled"
              onChange={(e) => onHandleChange(e)}
              name="newPassword"
              placeholder="New Password"
              type="password"
              style={{ marginBottom: "12px", backgroundColor: "#07232E" }}
            >
              {newPassword}
            </TextField>
            <TextField
              name="newRepeatedPassword"
              variant="filled"
              onChange={(e) => onHandleChange(e)}
              placeholder="New Repeated Password"
              type="password"
              style={{ marginBottom: "12px", backgroundColor: "#07232E" }}
            >
              {newRepeatedPassword}
            </TextField>

            <Button
              style={{
                marginTop: window.innerWidth ? "5px" : "10px",
                marginBottom: window.innerWidth ? "5px" : "10px",
                color: "white ",
                background: "#07454f",
                fontWeight: "bolder",
                fontSize: window.innerWidth < 668 ? "0.6rem" : "1rem",
              }}
              onClick={(e) => handleChangePassword(e)}
            >
              Change Password{" "}
            </Button>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityTradePage;
