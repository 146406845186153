import { Box } from "@material-ui/core";
import React from "react";
import "./index.scss";

function DepositPolicyPage() {
  return (
    <Box className="deposit-policy-page-container">
      <Box>
        <h1 className="deposit-policy-page-header">Deposit Policy</h1>
      </Box>
      <Box className="deposit-policy-page-textBox">
        <span className="deposit-policy-page-span">
          NordXGlobal (the “Company”) applies certain rules and guidelines
          relating to the movement of your money between us and you and which
          will be applicable to the deposits of money by you to your account
          with the Company. Deposits The minimum amount allowed for deposits is
          250 EUR/ GBP The Company applies a number of online fraud protection
          measures for deposits with cards, which include a maximum amount
          permitted for deposits in the total amount of 30 000 EUR/GBP per
          month. User may use multiple credit cards for deposits. For wire
          transfer, the minimum amount for deposit is 500 EUR/GBP. Company
          reserves the right not to accept card deposits from Users, meaning in
          this case only bank transfers are acceptable. Company will not apply
          funds deposited from bank account to your account with the Company
          until such funds are settled. The Company reserves the right to
          investigate with the applicable authorities any suspected illegal and
          fraudulent activity with cards used by you relating to the services of
          the Company. Company’s policy does not extend to charges your bank or
          payment provider may levy on you to make the transaction.
        </span>
      </Box>
    </Box>
  );
}

export default DepositPolicyPage;
