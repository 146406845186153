import { fetchWrapper } from "../helpers/fetchWrapper";

const loginUser = async (body) => {
  try {
    return await fetchWrapper.post("/users/login", body);
  } catch (e) {
    throw new Error(e);
  }
};

const loginUserAdmin = async (body) => {
  try {
    return await fetchWrapper.post("/users/admin/loginAdmin", body);
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

const changeOnlineActivity = async (id, isOnline) => {
  try {
    return await fetchWrapper.put(`/users/onlineActivity/${id}`, {
      isOnline,
    });
  } catch (e) {
    throw new Error(e);
  }
};

const autoLoginUser = async (email) => {
  try {
    return await fetchWrapper.post(`/users/autoLogin?email=${email}`);
  } catch (e) {
    throw new Error(e);
  }
};

const registerUser = async (body) => {
  try {
    return await fetchWrapper.post("/users/register", body);
  } catch (e) {
    throw new Error(e);
  }
};

const updateUser = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const deleteUser = async (id) => {
  try {
    return await fetchWrapper.delete(`/users/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUserClientsById = async (id) => {
  try {
    return await fetchWrapper.get(`/users/availableUsers/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUser = async (id) => {
  try {
    return await fetchWrapper.get(`/users/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUserBalance = async (id) => {
  try {
    return await fetchWrapper.get(`/users/balance/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getAllRegisteredUsers = async (payload) => {
  try {
    const searchQuery = new URLSearchParams(payload).toString();
    return await fetchWrapper.get(`/users/allUsers?${searchQuery}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getAllAgents = async (payload) => {
  try {
    return await fetchWrapper.get(`/users/allAgents`);
  } catch (e) {
    throw new Error(e);
  }
};

const changeUserPassword = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/password/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeUserBallance = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/ballance/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeAccountToLoginForbiddenStatus = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/loginForbidden/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeMultipleUsersBallance = async (body) => {
  try {
    return await fetchWrapper.put(`/users/ballances/multiple-ballances`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const setUserType = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/type/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeUserAgent = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/agent/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeAccountToInvestigationMode = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/investigation/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeMultipleUsersAgent = async (body) => {
  try {
    return await fetchWrapper.put(`/users/update-multiple/agent`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const updateUserAchievement = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/board/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const addNoteByLeadId = async (id, body) => {
  try {
    return await fetchWrapper.post(`/users/note/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};
const changeUserStatus = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/status/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};
const changeUserLastLogon = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/lastLogon/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const addComment = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/comment/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const updateUserFavourites = async (body) => {
  try {
    return await fetchWrapper.put(`/favourites`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const checkUserRestrictionAccount = async (body) => {
  try {
    return await fetchWrapper.post(`/users/restriction`, body);
  } catch (error) {
    throw new Error(error);
  }
};

const postForgotPassword = async (body) => {
  try {
    return await fetchWrapper.post(`/users/forgot-password`, body);
  } catch (error) {
    throw new Error(error);
  }
};

const postChangeForgotPassword = async (body) => {
  try {
    return await fetchWrapper.post(`/users/change/forgot-password`, body);
  } catch (error) {
    throw new Error(error);
  }
};

export const userServices = {
  loginUser,
  loginUserAdmin,
  registerUser,
  updateUser,
  deleteUser,
  checkUserRestrictionAccount,
  getUserClientsById,
  getUser,
  getUserBalance,
  getAllRegisteredUsers,
  getAllAgents,
  changeUserPassword,
  changeUserBallance,
  changeAccountToLoginForbiddenStatus,
  changeMultipleUsersBallance,
  setUserType,
  changeUserAgent,
  changeAccountToInvestigationMode,
  changeMultipleUsersAgent,
  updateUserAchievement,
  addNoteByLeadId,
  changeUserStatus,
  changeUserLastLogon,
  addComment,
  updateUserFavourites,
  autoLoginUser,
  changeOnlineActivity,
  postForgotPassword,
  postChangeForgotPassword,
};
